var $ = require('jquery');
var THREE = require('three');







/**
 * Based on http://www.emagix.net/academic/mscs-project/item/camera-sync-with-css3-and-webgl-threejs
 * @author mrdoob / http://mrdoob.com/
 */

THREE.CSS3DObject = function ( element ) {

    THREE.Object3D.call( this );

    this.element = element;
    this.element.style.position = 'absolute';

    this.addEventListener( 'removed', function (/* event*/ ) {

        if ( this.element.parentNode !== null ) {

            this.element.parentNode.removeChild( this.element );

        }

    } );

};

THREE.CSS3DObject.prototype = Object.create( THREE.Object3D.prototype );
THREE.CSS3DObject.prototype.constructor = THREE.CSS3DObject;

THREE.CSS3DSprite = function ( element ) {

    THREE.CSS3DObject.call( this, element );

};

THREE.CSS3DSprite.prototype = Object.create( THREE.CSS3DObject.prototype );
THREE.CSS3DSprite.prototype.constructor = THREE.CSS3DSprite;

//

THREE.CSS3DRenderer = function () {

    console.log( 'THREE.CSS3DRenderer', THREE.REVISION );

    var _width, _height;
    var _widthHalf, _heightHalf;

    var matrix = new THREE.Matrix4();

    var cache = {
        camera: { fov: 0, style: '' },
        objects: {}
    };

    var domElement = document.createElement( 'div' );
    domElement.style.overflow = 'hidden';

    domElement.style.WebkitTransformStyle = 'preserve-3d';
    domElement.style.MozTransformStyle = 'preserve-3d';
    domElement.style.oTransformStyle = 'preserve-3d';
    domElement.style.transformStyle = 'preserve-3d';

    this.domElement = domElement;

    var cameraElement = document.createElement( 'div' );

    cameraElement.style.WebkitTransformStyle = 'preserve-3d';
    cameraElement.style.MozTransformStyle = 'preserve-3d';
    cameraElement.style.oTransformStyle = 'preserve-3d';
    cameraElement.style.transformStyle = 'preserve-3d';

    domElement.appendChild( cameraElement );

    this.setClearColor = function () {};

    this.getSize = function() {

        return {
            width: _width,
            height: _height
        };

    };

    this.setSize = function ( width, height ) {

        _width = width;
        _height = height;

        _widthHalf = _width / 2;
        _heightHalf = _height / 2;

        domElement.style.width = width + 'px';
        domElement.style.height = height + 'px';

        cameraElement.style.width = width + 'px';
        cameraElement.style.height = height + 'px';

    };

    var epsilon = function ( value ) {

        return Math.abs( value ) < Number.EPSILON ? 0 : value;

    };

    var getCameraCSSMatrix = function ( matrix ) {

        var elements = matrix.elements;

        return 'matrix3d(' +
            epsilon( elements[ 0 ] ) + ',' +
            epsilon( - elements[ 1 ] ) + ',' +
            epsilon( elements[ 2 ] ) + ',' +
            epsilon( elements[ 3 ] ) + ',' +
            epsilon( elements[ 4 ] ) + ',' +
            epsilon( - elements[ 5 ] ) + ',' +
            epsilon( elements[ 6 ] ) + ',' +
            epsilon( elements[ 7 ] ) + ',' +
            epsilon( elements[ 8 ] ) + ',' +
            epsilon( - elements[ 9 ] ) + ',' +
            epsilon( elements[ 10 ] ) + ',' +
            epsilon( elements[ 11 ] ) + ',' +
            epsilon( elements[ 12 ] ) + ',' +
            epsilon( - elements[ 13 ] ) + ',' +
            epsilon( elements[ 14 ] ) + ',' +
            epsilon( elements[ 15 ] ) +
            ')';

    };

    var getObjectCSSMatrix = function ( matrix ) {

        var elements = matrix.elements;

        return 'translate3d(-50%,-50%,0) matrix3d(' +
            epsilon( elements[ 0 ] ) + ',' +
            epsilon( elements[ 1 ] ) + ',' +
            epsilon( elements[ 2 ] ) + ',' +
            epsilon( elements[ 3 ] ) + ',' +
            epsilon( - elements[ 4 ] ) + ',' +
            epsilon( - elements[ 5 ] ) + ',' +
            epsilon( - elements[ 6 ] ) + ',' +
            epsilon( - elements[ 7 ] ) + ',' +
            epsilon( elements[ 8 ] ) + ',' +
            epsilon( elements[ 9 ] ) + ',' +
            epsilon( elements[ 10 ] ) + ',' +
            epsilon( elements[ 11 ] ) + ',' +
            epsilon( elements[ 12 ] ) + ',' +
            epsilon( elements[ 13 ] ) + ',' +
            epsilon( elements[ 14 ] ) + ',' +
            epsilon( elements[ 15 ] ) +
            ')';

    };

    var renderObject = function ( object, camera ) {

        if ( object instanceof THREE.CSS3DObject ) {

            var style;

            if ( object instanceof THREE.CSS3DSprite ) {

                // http://swiftcoder.wordpress.com/2008/11/25/constructing-a-billboard-matrix/

                matrix.copy( camera.matrixWorldInverse );
                matrix.transpose();
                matrix.copyPosition( object.matrixWorld );
                matrix.scale( object.scale );

                matrix.elements[ 3 ] = 0;
                matrix.elements[ 7 ] = 0;
                matrix.elements[ 11 ] = 0;
                matrix.elements[ 15 ] = 1;

                style = getObjectCSSMatrix( matrix );

            } else {

                style = getObjectCSSMatrix( object.matrixWorld );

            }

            var element = object.element;
            var cachedStyle = cache.objects[ object.id ];

            if ( cachedStyle === undefined || cachedStyle !== style ) {

                element.style.WebkitTransform = style;
                element.style.MozTransform = style;
                element.style.oTransform = style;
                element.style.transform = style;

                cache.objects[ object.id ] = style;

            }

            if ( element.parentNode !== cameraElement ) {

                cameraElement.appendChild( element );

            }

        }

        for ( var i = 0, l = object.children.length; i < l; i ++ ) {

            renderObject( object.children[ i ], camera );

        }

    };

    this.render = function ( scene, camera ) {

        var fov = 0.5 / Math.tan( THREE.Math.degToRad( camera.fov * 0.5 ) ) * _height;

        if ( cache.camera.fov !== fov ) {

            domElement.style.WebkitPerspective = fov + 'px';
            domElement.style.MozPerspective = fov + 'px';
            domElement.style.oPerspective = fov + 'px';
            domElement.style.perspective = fov + 'px';

            cache.camera.fov = fov;

        }

        scene.updateMatrixWorld();

        if ( camera.parent === null ) camera.updateMatrixWorld();

        camera.matrixWorldInverse.getInverse( camera.matrixWorld );

        var style = 'translate3d(0,0,' + fov + 'px)' + getCameraCSSMatrix( camera.matrixWorldInverse ) +
            ' translate3d(' + _widthHalf + 'px,' + _heightHalf + 'px, 0)';

        if ( cache.camera.style !== style ) {

            cameraElement.style.WebkitTransform = style;
            cameraElement.style.MozTransform = style;
            cameraElement.style.oTransform = style;
            cameraElement.style.transform = style;

            cache.camera.style = style;

        }

        renderObject( scene, camera );

    };

};











const PANORAMA_DOM_ID = '#panorama';
const PANORAMA_VIDEO = 'assets/video/video1Opt.mp4';
class PanoramaScene {

    constructor() {
        this.init();
    }

    init(){
        var canvasDOM = $(PANORAMA_DOM_ID);
        var viewArr = [this.createView(canvasDOM)];

        this.createInitialObjs(viewArr[0]);

        this.viewArr = viewArr;
        canvasDOM[0].addEventListener( 'mousedown', (e) => this.onDocumentMouseDown(e), false );
        canvasDOM[0].addEventListener( 'mousemove', (e) => this.onDocumentMouseMove(e), false );
        canvasDOM[0].addEventListener( 'mouseup', (e) => this.onDocumentMouseUp(e), false );
        canvasDOM[0].addEventListener( 'mousewheel', (e) => this.onDocumentMouseWheel(e), false );
        canvasDOM[0].addEventListener( 'MozMousePixelScroll', (e) => this.onDocumentMouseWheel(e), false);

        window.addEventListener( 'resize', (e) => this.onWindowResize(e), false );
        this.render();
    }

    getVideoDOM() {
        if(!this.videoDOM) {
            var video = document.createElement( 'video' );
            video.width = 640;
            video.height = 360;
            video.autoplay = true;
            video.loop = true;
            video.muted = true;
            video.preload = true;
            video.src = PANORAMA_VIDEO;

            this.videoDOM = video;
        }
        return this.videoDOM;
    }

    createInitialObjs(view){

        var sphere = new THREE.SphereGeometry( 500, 60, 40 );
        sphere.scale( - 1, 1, 1 );


        var texture = new THREE.VideoTexture( this.getVideoDOM() );
        texture.minFilter = THREE.LinearFilter;

        var sphereMaterial   = new THREE.MeshBasicMaterial( { map : texture } );

        var sphereMesh = new THREE.Mesh(sphere, sphereMaterial);
        view.scene.add(sphereMesh);
    }

    createView(canvasDOM){
        var renderer = new THREE.WebGLRenderer({ antialias: false });

        var width = canvasDOM.width();
        var height = canvasDOM.height(); //300;

        renderer.setPixelRatio( window.devicePixelRatio );
        renderer.setSize(width, height);
        canvasDOM.append(renderer.domElement);

        var hotspotRenderer = new THREE.CSS3DRenderer();
        hotspotRenderer.setSize(width, height);
        hotspotRenderer.domElement.style.position = 'absolute';
        hotspotRenderer.domElement.style.top = 0;
        canvasDOM.append(hotspotRenderer.domElement);

        var scene = new THREE.Scene();
        var hotspotScene = new THREE.Scene();

        var camera = new THREE.PerspectiveCamera( 75, window.innerWidth / window.innerHeight, 10, 1100 );
        camera.target = new THREE.Vector3( 0, 0, 0 );
        scene.add(camera);
        hotspotScene.add(camera);

        var view = {};
        view.camera = camera;
        //view.cameraControls = cameraControls;
        view.renderer = renderer;
        view.scene = scene;
        view.hotspotRenderer = hotspotRenderer;
        view.hotspotScene = hotspotScene;
        view.lat = 0;
        view.lon = 180;

        return view;
    }

    createHotspot(x,y,z, onClickFunc) {
        var element = document.createElement( 'div' );
        element.classList.add('hotspot');
        //
        var css3DObj = new THREE.CSS3DObject( element );
        css3DObj.position.x = x;
        css3DObj.position.y = y;
        css3DObj.position.z = z;
        element.parent = css3DObj;
        css3DObj.element.onclick = onClickFunc;

        this.viewArr[0].hotspotScene.add(css3DObj);
        return css3DObj;
    }

    getIntersection(clientX,clientY) {
        var view = this.viewArr[0];
        var raycaster = new THREE.Raycaster();
        var mouse = new THREE.Vector2();
        mouse.x = ( clientX / window.innerWidth ) * 2 - 1;
        mouse.y = - ( clientY / window.innerHeight ) * 2 + 1;

        raycaster.setFromCamera( mouse, view.camera );
        var intersects = raycaster.intersectObjects( view.scene.children );

        var faceNormal = intersects[0].face.normal;
        return faceNormal;
    }

    onDocumentMouseDown( event ) {
        this.viewArr.forEach((view) => {
            event.preventDefault();
            view.isUserInteracting = true;
            view.onPointerDownPointerX = event.clientX;
            view.onPointerDownPointerY = event.clientY;
            view.onPointerDownLon = view.lon;
            view.onPointerDownLat = view.lat;

        });
    }

    onDocumentMouseMove( event ) {
        this.viewArr.forEach((view) => {
            if ( view.isUserInteracting === true ) {
                view.lon = ( view.onPointerDownPointerX - event.clientX ) * 0.1 + view.onPointerDownLon;
                view.lat = ( event.clientY - view.onPointerDownPointerY ) * 0.1 + view.onPointerDownLat;

            }
        });
    }

    onDocumentMouseUp( /*event*/ ) {
        this.viewArr.forEach((view) => {
            view.isUserInteracting = false;
        });

    }

    onDocumentMouseWheel( event ) {
        this.viewArr.forEach((view) => {
            // WebKit
            if ( event.wheelDeltaY ) {
                view.camera.fov -= event.wheelDeltaY * 0.05;
                // Opera / Explorer 9
            } else if ( event.wheelDelta ) {
                view.camera.fov -= event.wheelDelta * 0.05;
                // Firefox
            } else if ( event.detail ) {
                view.camera.fov += event.detail * 1.0;
            }
            view.camera.updateProjectionMatrix();
        });
    }

    onWindowResize() {

        this.viewArr.forEach((view) => {
            view.camera.aspect = window.innerWidth / window.innerHeight;
            view.camera.updateProjectionMatrix();

            view.renderer.setSize( window.innerWidth, window.innerHeight );
        });

    }

    animate(){
        this.update();
        this.render();
        requestAnimationFrame(this.animate.bind(this));
    }

    update(){
        var viewArr = this.viewArr;
        viewArr.forEach((view) => this.updateView(view));

        this.render();
    }

    updateView(view){
        view.lat = Math.max( - 85, Math.min( 85, view.lat ) );
        view.phi = THREE.Math.degToRad( 90 - view.lat );
        view.theta = THREE.Math.degToRad( view.lon );

        view.camera.target.x = 500 * Math.sin( view.phi ) * Math.cos( view.theta );
        view.camera.target.y = 500 * Math.cos( view.phi );
        view.camera.target.z = 500 * Math.sin( view.phi ) * Math.sin( view.theta );

        view.camera.lookAt( view.camera.target );

        // distortion
        //view.camera.position.copy( view.camera.target ).negate();
    }

    renderView(view) {
        var renderer = view.renderer;
        renderer.setClearColor( '#FFFFFF' );
        renderer.render( view.scene, view.camera );
        var hotspotRenderer = view.hotspotRenderer;
        hotspotRenderer.setClearColor( '#FFFFFF' );
        hotspotRenderer.render( view.hotspotScene, view.camera );
    }

    render(){
        this.viewArr.forEach((view) => this.renderView(view));
    }

    test() {
        console.log('PANOMARA SCENE');
    }
}

module.exports = PanoramaScene; // eslint-disable-line no-undef
