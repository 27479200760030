var $ = require('jquery')(window);

$.ready(function() {

    var THREE = require('three');

    var PanoramaScene = require('./panoramaScene.js');
    var panorama = new PanoramaScene();
    panorama.animate();
    panorama.test();
    var videoDOM = panorama.getVideoDOM();
    var imgDOM = document.getElementById('hover-image');

    var hotspotsArr = [
        {'normal':{'x':-0.4001934237179365,'y':-0.6782985100975608,'z':0.6162437462663174},'currentTime':55.112073, name: 'hotspot-a', hasStopped: false, file:'assets/img/a.jpg'},
        {'normal':{'x':-0.26332428385725043,'y':-0.6782982086156499,'z':0.6859824062757486},'currentTime':93.818146, name: 'hotspot-b', hasStopped: false, file:'assets/img/b.jpg'},
        {'normal':{'x':-0.7065631030783689,'y':-0.039206666990863594,'z':-0.7065631030783689},'currentTime':130.355232, name: 'hotspot-c', hasStopped: false, file:'assets/img/c.jpg'}
    ];

    hotspotsArr.forEach((elem) => {
        var normal = new THREE.Vector3(elem.normal.x,elem.normal.y,elem.normal.z);
        normal.normalize();
        var point = normal.multiplyScalar(500);
        var css3DObj = panorama.createHotspot(point.x,point.y,point.z);
        css3DObj.lookAt(normal.normalize());
        css3DObj.element.id =  elem.name;
        css3DObj.element.classList.add('hide');
        elem.element = css3DObj.element;

        elem.image = new Image();
        elem.image.src = elem.file;
        elem.element.addEventListener('mouseover',  () => {
            imgDOM.src = elem.file;
            imgDOM.style.display = 'block';
        });
        elem.element.addEventListener('mouseout',  () => {
            imgDOM.style.display = 'none';
        });
    });

    /*
    document.addEventListener('click', (event) => {
        var normal = panorama.getIntersection(event.clientX,event.clientY);
        var point = normal.multiplyScalar(500);
        var css3DObj = panorama.createHotspot(point.x,point.y,point.z);
        css3DObj.lookAt(normal.normalize());

        console.log(JSON.stringify({normal:normal,currentTime:videoDOM.currentTime}));
    });*/


    document.getElementById('play-button').addEventListener('click',() => {
        if(videoDOM.paused)
            videoDOM.play();
        else
            videoDOM.pause();

    });

    var toHHMMSS = function (str) {
        var sec_num = parseInt(str, 10); // don't forget the second param
        var hours   = Math.floor(sec_num / 3600);
        var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
        var seconds = sec_num - (hours * 3600) - (minutes * 60);

        if (hours   < 10) {hours   = '0'+hours;}
        if (minutes < 10) {minutes = '0'+minutes;}
        if (seconds < 10) {seconds = '0'+seconds;}
        var time    = hours+':'+minutes+':'+seconds;
        return time;
    };

    var sliderDOM = document.getElementById('video-slider');
    var videoTimeDOM = document.getElementById('video-time');
    var mouseIsDown = false;

    var hotspotArrTimeoutFunc = (elem) => {
        var stopTime = elem.currentTime;
        if(Math.abs(stopTime-videoDOM.currentTime) < 1) {
            elem.element.classList.remove('hide');
            if(!elem.hasStopped) {
                videoDOM.pause();
                elem.hasStopped = true;
            }
        } else {
            elem.element.classList.add('hide');
            elem.hasStopped = false;
        }
    };

    var timeoutFunc = () => {
        if(!mouseIsDown && !videoDOM.paused) {
            var percentageViewed = videoDOM.currentTime/videoDOM.duration;
            sliderDOM.value = percentageViewed;
            videoTimeDOM.innerHTML = toHHMMSS(videoDOM.currentTime);


            hotspotsArr.forEach(hotspotArrTimeoutFunc);

        }
        //console.log(percentageViewed);
        setTimeout(timeoutFunc, 100);
    };


    sliderDOM.addEventListener('mousedown',() => mouseIsDown = true);
    sliderDOM.addEventListener('mouseup',() => mouseIsDown = false);
    sliderDOM.addEventListener('change',() => {
        videoDOM.currentTime = sliderDOM.value*videoDOM.duration;
    });
    timeoutFunc();

});

